<template>
   <modal
    :width="1150"
    :adaptive="true"
    class="tradeDetailModal CEModal modal-scroll-bar"
    name="TradeDetailModal"
  >
    <button class="button cus-close-modal_btn" @click="closeModal()">
      <img src="/assets/img/close.svg" alt />
    </button>

    <div class="df-c">
      <img src="/assets/img/logo/3.png" alt="" class="company-logo mx-10">
      <h4>
        The Franchise Show, Atlanta, US
      </h4>
    </div>
    <div class="row mt-10">
      <div class="col-sm-4">
        <ExhibitionItems :isDetailModal="true" :item="test1"></ExhibitionItems>
      </div>
      <div class="col-sm-4">
        <ExhibitionItems :isDetailModal="true" :item="test2"></ExhibitionItems>
      </div>
      <div class="col-sm-4">
        <ExhibitionItems :isDetailModal="true" :item="test3"></ExhibitionItems>
      </div>
      <div class="col-sm-4">
        <ExhibitionItems :isDetailModal="true" :item="test1"></ExhibitionItems>
      </div>
      <div class="col-sm-4">
        <ExhibitionItems :isDetailModal="true" :item="test2"></ExhibitionItems>
      </div>
      <div class="col-sm-4">
        <ExhibitionItems :isDetailModal="true" :item="test1"></ExhibitionItems>
      </div>
    </div>
  </modal>
</template>

<script>
import ExhibitionItems from "./ExhibitionItems.vue" 
export default {
  name: 'TradeDetailModal',
  components: {
    ExhibitionItems
  }, 
  data() {
    return {
      test1: {
        country: 'us',
        logo: '3.png'
      },
      test2: {
        country: 'fr',
        logo: '2.png'
      },
      test3: {
        country: 'gb',
        logo: '1.png'
      },
    }
  },
  methods: {
    closeModal() {
      this.$modal.hide("TradeDetailModal");
    },
  }
}
</script>

<style lang="scss">
  .tradeDetailModal .vm--modal{
    padding: 20px 50px;
  }
</style>

<style lang="scss" scoped>
.tradeDetailModal .company-logo {
  width: 70px;
  height: 70px;
}
</style>